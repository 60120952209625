<template>
  <div class="d-flex flex-column align-items-sm-start">
    <b-modal
      v-model="modalControl.showCreationModal"
      size="lg"
      :title="$t('timetracking.create-ttu')"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
    >
      <div>
        <b-container fluid>
          <b-row class="mb-4">
            <b-card no-body class="w-100">
              <b-tabs card content-class="m-2">
                <b-tab
                  :title="$t('timetracking.modals.createModal.tab-existing')"
                  @click="toggleTicketTabs('existing')"
                >
                  <b-row>
                    <b-col>
                      <b-form-text class="mb-1" v-if="existingTicket.id === ''"
                        >{{
                          this.ticketSuggestionQuery.length > 0
                            ? $t('timetracking.modals.createModal.description-tickets-search')
                            : $t('timetracking.modals.createModal.description-tickets-recent')
                        }}
                      </b-form-text>
                    </b-col>
                    <b-col class="col-md-auto">
                      <b-form-text
                        class="mb-1"
                        v-b-tooltip.hover
                        :title="$t('timetracking.tooltip-standard-cost-unit')"
                      >
                        <b-icon icon="question-circle-fill" variant="info" class="ml-2 mr-1" />
                        <a
                          id="cost-unit-link"
                          class="mb-1"
                          href="https://goldflam.atlassian.net/wiki/spaces/GOLDFLAMIN/pages/2074312705/Standard-Kostenstellen"
                          target="_blank"
                          >{{ $t('timetracking.standard-cost-unit') }}</a
                        >
                      </b-form-text>
                    </b-col>
                  </b-row>
                  <vue-typeahead-bootstrap
                    :placeholder="$t('timetracking.search-ticket')"
                    ref="searchTicketsDropdown"
                    v-model="ticketSuggestionQuery"
                    @input="ticketSuggestionDebouncer"
                    :show-all-results="true"
                    @hit="existingTicket = $event"
                    :data="ticketSuggestionList"
                    :showOnFocus="true"
                    :serializer="ticket => ticketDropdownSerializer(ticket)"
                    :prepend="$t('general.ticket')"
                    class="flex-fill"
                    :maxMatches="ticketSuggestionQuery.length > 0 ? ticketSuggestionList.length : 20"
                  />
                </b-tab>
                <b-tab
                  :title="$t('timetracking.modals.createModal.tab-recent')"
                  @click="toggleTicketTabs('recent')"
                  lazy
                >
                  <b-overlay
                    :show="ticketSuggestionList.length === 0"
                    spinner-type="grow"
                    spinner-small
                    rounded="sm"
                    class="flex-fill"
                  >
                    <vue-typeahead-bootstrap
                      :placeholder="$t('timetracking.modals.createModal.placeholder-recent')"
                      ref="recentTicketsDropdown"
                      v-model="ticketSuggestionQuery"
                      @hit="existingTicket = $event"
                      :data="ticketSuggestionList"
                      :showOnFocus="true"
                      :serializer="ticket => ticketDropdownSerializer(ticket)"
                      :maxMatches="20"
                    >
                      <template slot="prepend">
                        <b-form-select
                          v-model="recentTicketsFilterUserId"
                          :options="activeUserList"
                          @change="getRecentTickets"
                          id="recentTicketsFilterUserId"
                        >
                          <template #first>
                            <b-form-select-option :value="-1">{{ $t('general.all-users') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </template>
                    </vue-typeahead-bootstrap>
                  </b-overlay>
                </b-tab>
                <b-tab :title="$t('timetracking.modals.createModal.tab-new')" @click="toggleTicketTabs('new')" lazy>
                  <b-container>
                    <b-row class="mb-2">
                      <b-col class="pl-0">
                        <b-form-text class="mb-1" v-if="timeTrackingUnit.ticket.project.id === ''"
                          >{{
                            this.projectSuggestionQuery.length > 0
                              ? $t('timetracking.modals.createModal.description-projects-search')
                              : $t('timetracking.modals.createModal.description-projects-recent')
                          }}
                        </b-form-text>
                      </b-col>
                      <b-col class="col-md-auto">
                        <b-form-text
                          class="mb-1"
                          v-b-tooltip.hover
                          :title="$t('timetracking.tooltip-standard-cost-unit')"
                        >
                          <b-icon icon="question-circle-fill" variant="info" class="ml-2 mr-1" />
                          <a
                            id="cost-unit-link"
                            class="mb-1"
                            href="https://goldflam.atlassian.net/wiki/spaces/GOLDFLAMIN/pages/2074312705/Standard-Kostenstellen"
                            target="_blank"
                            >{{ $t('timetracking.standard-cost-unit') }}</a
                          >
                        </b-form-text>
                      </b-col>
                      <vue-typeahead-bootstrap
                        :placeholder="$t('timetracking.search-project')"
                        ref="projectDropdown"
                        v-model="projectSuggestionQuery"
                        @input="projectSuggestionDebouncer"
                        @hit="setProjectAndLoadParentTicketSuggestions($event)"
                        :data="projectSuggestionList"
                        :showOnFocus="true"
                        :serializer="project => project.fullName + ' (' + project.customer.fullName + ')'"
                        :prepend="$t('general.project')"
                        class="w-100"
                      />
                    </b-row>
                    <b-row class="mb-2">
                      <b-input-group :prepend="$t('general.ticket')">
                        <b-form-input
                          class="col-3"
                          id="timetrackingUnitModalTicketId"
                          :placeholder="$t('general.ticketIdShort')"
                          :disabled="!timeTrackingUnit.ticket.project.id"
                          v-model="timeTrackingUnit.ticket.ticketId"
                          :state="!timeTrackingUnit.ticket.project.id ? null : isTicketUidValid"
                          type="text"
                        />
                        <b-form-input
                          id="timetrackingUnitModalTicketName"
                          :placeholder="$t('general.ticketName')"
                          :disabled="!timeTrackingUnit.ticket.project.id"
                          v-model="timeTrackingUnit.ticket.name"
                          type="text"
                        />
                      </b-input-group>
                    </b-row>
                    <b-row>
                      <vue-typeahead-bootstrap
                        :disabled="!timeTrackingUnit.ticket.project.id"
                        :placeholder="$t('ticket.parent-ticket-typeahead-placeholder')"
                        ref="searchParentTicketsDropdown"
                        v-model="parentTicketSuggestionQuery"
                        @hit="timeTrackingUnit.ticket.parentTicketId = $event.id"
                        :data="parentTicketSuggestions"
                        :showOnFocus="true"
                        :serializer="
                          parentTicket => (parentTicket.ticketId ? parentTicket.ticketId + ' ' : '') + parentTicket.name
                        "
                        :prepend="$t('general.parentTicket')"
                        class="flex-fill"
                        :maxMatches="parentTicketSuggestionQuery.length > 0 ? parentTicketSuggestions.length : 20"
                      />
                    </b-row>
                  </b-container>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-row>
          <b-row class="mb-4">
            <b-card class="w-100">
              <b-row class="m-2">
                <b-form-text class="col-12 mt-0 px-0">
                  <label for="date-input" class="col-3 mb-1 pl-0">{{ $t('timetracking.date') }}</label>
                  <label for="start-input" class="col-3 mb-1">{{ $t('timetracking.start') }}</label>
                  <label for="end-input" class="col-3 mb-1">{{ $t('timetracking.end') }}</label>
                  <label for="break-Length" class="col-2 mb-1 pl-0">{{ $t('timetracking.break-duration') }}</label>
                </b-form-text>
                <Datepicker
                  id="date-input"
                  v-model="timeTrackingUnit.start.date"
                  :placeholder="this.$t('timetracking.date')"
                  :locale="this.$store.getters.getLocale"
                  :reset-button="false"
                  class="col-3"
                />
                <b-input-group class="col-3 pr-0">
                  <b-input id="start-input" v-model="timeTrackingUnit.start.time" type="time" />
                  <b-input-group-append>
                    <b-button id="start-now" @click="setStartTimeToCurrent">
                      {{ $t('datetime.now') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-input-group class="col-3">
                  <b-input id="end-input" v-model="timeTrackingUnit.end.time" type="time" />
                  <b-input-group-append>
                    <b-button id="end-now" @click="setEndTimeToCurrent">
                      {{ $t('datetime.now') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-input
                  id="break-Length"
                  v-model="timeTrackingUnit.breakDurationInMinutes"
                  type="number"
                  class="col-2"
                />
              </b-row>
            </b-card>
          </b-row>
          <b-row class="mb-3">
            <b-input-group :prepend="$t('timetracking.comment')">
              <b-input id="comment" v-model="timeTrackingUnit.comment" type="text" />
            </b-input-group>
          </b-row>

          <b-alert
            v-if="timeTrackingUnit.status === 'RUNNING' && runningTimeTrackingUnit"
            show
            class="flex-fill"
            variant="warning"
            >{{ $t('timetracking.warning-already-running-ttu') }}
          </b-alert>
        </b-container>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100" v-if="timeTrackingUnit.ticket.ticketId !== null">
          <b-button
            v-if="timeTrackingUnit.status === 'RUNNING' && runningTimeTrackingUnit"
            class="float-right"
            :disabled="modalDisabled"
            variant="primary"
            @click="finishAndCreateTimeTrackingUnit"
          >
            {{
              timeTrackingUnit.status === 'RUNNING' ? $t('timetracking.save-ttu-running') : $t('timetracking.save-ttu')
            }}
          </b-button>
          <b-button
            v-else
            class="float-right"
            :disabled="modalDisabled"
            variant="primary"
            @click="createTimeTrackingUnit"
          >
            {{
              timeTrackingUnit.status === 'RUNNING' ? $t('timetracking.save-ttu-running') : $t('timetracking.save-ttu')
            }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-model="modalControl.showEditModal"
      size="lg"
      :title="$t('timetracking.edit-ttu')"
      :cancel-title="$t('general.cancel')"
      cancel-variant="outline-secondary"
      :ok-title="$t('general.save')"
      :ok-disabled="editModalDisabled"
      @ok="editTimeTrackingUnit"
      @hidden="onEditModalClose"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
    >
      <div>
        <b-container fluid>
          <b-row class="mb-4">
            <b-card no-body class="w-100">
              <b-tabs card content-class="m-2">
                <b-tab :title="$t('timetracking.modals.editModal.tab-edit')" @click="toggleEditTicketTabs('edit')">
                  <b-container>
                    <b-row class="mb-2">
                      <vue-typeahead-bootstrap
                        :placeholder="$t('timetracking.search-project')"
                        ref="editProjectDropdown"
                        v-model="projectSuggestionQuery"
                        @input="projectSuggestionDebouncer"
                        @hit="fillProjectAndUpdateSuggestionList($event)"
                        :data="projectSuggestionList"
                        :showOnFocus="true"
                        :serializer="project => project.fullName + ' (' + project.customer.fullName + ')'"
                        :prepend="$t('general.project')"
                        class="w-100"
                      />
                    </b-row>
                    <b-row class="mb-2">
                      <b-input-group :prepend="$t('general.ticket')">
                        <vue-typeahead-bootstrap
                          placeholder="UID"
                          ref="editTicketsUidDropdown"
                          id="editTicketUidTypeahead"
                          v-model="ticketIdSuggestionQuery"
                          @input="editTicketSuggestionDebouncer('UID')"
                          @hit="setTicketNameAndUidToQueryDebouncer($event)"
                          :data="ticketIdSuggestionList"
                          :showOnFocus="true"
                          :serializer="ticket => editTicketDropdownSerializer(ticket)"
                          :maxMatches="20"
                          :disabled="!timeTrackingUnit.ticket.project.id"
                          :state="isTicketUidValid"
                        />
                        <vue-typeahead-bootstrap
                          placeholder="Ticket Name"
                          ref="editTicketsNameDropdown"
                          id="editTicketNameTypeahead"
                          v-model="ticketNameSuggestionQuery"
                          @input="editTicketSuggestionDebouncer('NAME')"
                          @hit="setTicketNameAndUidToQueryDebouncer($event)"
                          :data="ticketNameSuggestionList"
                          :showOnFocus="true"
                          :serializer="ticket => editTicketDropdownSerializer(ticket)"
                          :maxMatches="20"
                          class="flex-fill"
                          :disabled="!timeTrackingUnit.ticket.project.id"
                        />
                      </b-input-group>
                    </b-row>

                    <b-row>
                      <vue-typeahead-bootstrap
                        :disabled="!timeTrackingUnit.ticket.project.id"
                        :placeholder="$t('ticket.parent-ticket-typeahead-placeholder')"
                        ref="editParentTicketsDropdown"
                        v-model="parentTicketSuggestionQuery"
                        @hit="timeTrackingUnit.ticket.parentTicketId = $event.id"
                        :data="parentTicketSuggestions"
                        :showOnFocus="true"
                        :serializer="
                          parentTicket => (parentTicket.ticketId ? parentTicket.ticketId + ' ' : '') + parentTicket.name
                        "
                        :prepend="$t('general.parentTicket')"
                        class="flex-fill"
                        :maxMatches="parentTicketSuggestionQuery.length > 0 ? parentTicketSuggestions.length : 20"
                      />
                    </b-row>

                    <b-row class="mt-3">
                      <b-alert v-if="showEditTicketAlertExisting" show class="flex-fill" variant="info"
                        >{{ $t('timetracking.modals.editModal.info-ticket-exists', [this.numberOfAssociatedTTUs]) }}
                      </b-alert>
                      <b-alert v-if="showEditTicketAlertNew" show class="flex-fill" variant="info">
                        {{ $t('timetracking.modals.editModal.info-new-ticket') }}
                      </b-alert>
                      <b-alert v-if="showEditTicketAlertChanged" show class="flex-fill" variant="info">
                        {{ $t('timetracking.modals.editModal.info-ticketname-changed', [this.numberOfAssociatedTTUs]) }}
                      </b-alert>
                    </b-row>
                  </b-container>
                </b-tab>
                <b-tab
                  :title="$t('timetracking.modals.createModal.tab-existing')"
                  @click="toggleEditTicketTabs('existing')"
                >
                  <b-row class="mb-2">
                    <b-col>
                      <b-form-text class="mb-1" v-if="existingTicket.id === ''"
                        >{{
                          this.ticketSuggestionQuery.length > 0
                            ? $t('timetracking.modals.createModal.description-tickets-search')
                            : $t('timetracking.modals.createModal.description-tickets-recent')
                        }}
                      </b-form-text>
                    </b-col>
                    <b-col class="col-md-auto">
                      <b-form-text
                        class="mb-1"
                        v-b-tooltip.hover
                        :title="$t('timetracking.tooltip-standard-cost-unit')"
                      >
                        <b-icon icon="question-circle-fill" variant="info" class="ml-2 mr-1" />
                        <a
                          id="cost-unit-link"
                          class="mb-1"
                          href="https://goldflam.atlassian.net/wiki/spaces/GOLDFLAMIN/pages/2074312705/Standard-Kostenstellen"
                          target="_blank"
                          >{{ $t('timetracking.standard-cost-unit') }}</a
                        >
                      </b-form-text>
                    </b-col>
                  </b-row>
                  <vue-typeahead-bootstrap
                    :placeholder="$t('timetracking.search-ticket')"
                    ref="searchTicketsDropdown"
                    v-model="ticketSuggestionQuery"
                    @input="ticketSuggestionDebouncer"
                    :show-all-results="true"
                    @hit="existingTicket = $event"
                    :data="ticketSuggestionList"
                    :showOnFocus="true"
                    :serializer="ticket => ticketDropdownSerializer(ticket)"
                    :prepend="$t('general.ticket')"
                    class="flex-fill"
                    :maxMatches="20"
                  />
                </b-tab>
                <b-tab
                  :title="$t('timetracking.modals.createModal.tab-recent')"
                  @click="toggleEditTicketTabs('recent')"
                  lazy
                >
                  <b-overlay
                    :show="ticketSuggestionList.length === 0"
                    spinner-type="grow"
                    spinner-small
                    rounded="sm"
                    class="flex-fill"
                  >
                    <vue-typeahead-bootstrap
                      :placeholder="$t('timetracking.modals.createModal.placeholder-recent')"
                      ref="recentTicketsDropdown"
                      v-model="ticketSuggestionQuery"
                      @hit="existingTicket = $event"
                      :data="ticketSuggestionList"
                      :showOnFocus="true"
                      :serializer="ticket => ticketDropdownSerializer(ticket)"
                      :maxMatches="20"
                    >
                      <template slot="prepend">
                        <b-form-select
                          v-model="recentTicketsFilterUserId"
                          :options="activeUserList"
                          @change="getRecentTickets"
                          id="recentTicketsFilterUserId"
                        >
                          <template #first>
                            <b-form-select-option :value="-1">{{ $t('general.all-users') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </template>
                    </vue-typeahead-bootstrap>
                  </b-overlay>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-row>

          <b-row class="mb-4">
            <b-card class="w-100">
              <b-row class="m-2">
                <b-form-text class="col-12 mt-0 px-0">
                  <label for="date-input" class="col-3 mb-1 pl-0">{{ $t('timetracking.date') }}</label>
                  <label for="start-input" class="col-3 mb-1">{{ $t('timetracking.start') }}</label>
                  <label for="end-input" class="col-3 mb-1">{{ $t('timetracking.end') }}</label>
                  <label for="break-Length" class="col-2 mb-1 pl-0">{{ $t('timetracking.break-duration') }}</label>
                </b-form-text>
                <Datepicker
                  id="date-input"
                  v-model="timeTrackingUnit.start.date"
                  :placeholder="this.$t('timetracking.date')"
                  :locale="this.$store.getters.getLocale"
                  :reset-button="false"
                  class="col-3"
                />
                <b-input-group class="col-3 pr-0">
                  <b-input id="start-input" v-model="timeTrackingUnit.start.time" type="time" />
                  <b-input-group-append>
                    <b-button id="start-now" @click="setStartTimeToCurrent">
                      {{ $t('datetime.now') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-input-group class="col-3">
                  <b-input id="end-input" v-model="timeTrackingUnit.end.time" type="time" />
                  <b-input-group-append>
                    <b-button id="end-now" @click="setEndTimeToCurrent">
                      {{ $t('datetime.now') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-input
                  id="break-Length"
                  v-model="timeTrackingUnit.breakDurationInMinutes"
                  type="number"
                  class="col-2"
                />
              </b-row>
            </b-card>
          </b-row>
          <b-row class="mb-4">
            <b-input-group :prepend="$t('timetracking.comment')">
              <b-input id="comment" v-model="timeTrackingUnit.comment" type="text" />
            </b-input-group>
          </b-row>
          <b-row class="mb-3">
            <b-input-group :prepend="$t('general.status')">
              <b-form-select
                id="status"
                placeholder="status"
                v-model="timeTrackingUnit.status"
                :options="statusOptions"
              />
            </b-input-group>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <b-modal
      v-model="modalControl.delete.show"
      :title="deleteModalTitle"
      :cancel-title="$t('general.cancel')"
      cancel-variant="outline-secondary"
      :ok-title="$t('general.delete')"
      ok-variant="danger"
      @ok="deleteModalSubmit"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
    >
      {{
        modalControl.delete.type === 'ttu' ? $t('timetracking.delete-text-ttu') : $t('timetracking.delete-text-ticket')
      }}
    </b-modal>

    <b-container fluid id="headContainer" class="p-0 mb-3">
      <b-row align-h="between" align-v="end">
        <b-col>
          <b-row align-v="end">
            <b-col cols="2" style="padding-right: 0px">
              <b-button
                v-if="hasRunningTtuAndStartIsNotToday"
                @click="editRunningTtuFromPreviousDay()"
                variant="warning"
                class="mr-3 w-100"
              >
                {{ $t('timetracking.finish-running-ttu') }}
              </b-button>
              <b-button v-else @click="openCreationModal" variant="info" class="mr-3 w-100">
                {{ $t('timetracking.create-ttu') }}
              </b-button>
            </b-col>
            <b-col cols="2" class="ml-2" style="padding: 0px">
              <vue-autosuggest
                v-model="query"
                :section-configs="{ default: { limit: 6 } }"
                :suggestions="suggestions"
                :input-props="{ id: 'searchField', placeholder: this.$t('timetracking.search') }"
                @selected="selectHandler"
                @focusout="unselectIfQueryEmpty"
              >
                <template slot-scope="{ suggestion }">
                  <span class="my-suggestion-item">{{ suggestion.item }}</span>
                </template>
              </vue-autosuggest>
            </b-col>
            <b-col cols="2" class="ml-2 px-0">
              <Datepicker
                id="date-pick-start"
                v-model="datePickStart"
                :locale="this.$store.getters.getLocale"
                :placeholder="this.$t('datetime.start-date')"
              />
            </b-col>
            <b-col cols="2" class="ml-2 px-0">
              <Datepicker
                id="date-pick-end"
                v-model="datePickEnd"
                :locale="this.$store.getters.getLocale"
                :placeholder="this.$t('datetime.end-date')"
                :min="datePickStart"
              />
            </b-col>
            <b-col cols="2" class="ml-2 px-0">
              <b-button style="width: 100%" @click="clearFilter">{{ $t('timetracking.clear-all-filters') }}</b-button>
            </b-col>
          </b-row>
          <b-row align-v="center" class="mt-2 mb-4">
            <b-col cols="4" class="ml-0" style="padding-right: 0px" />
            <b-col cols="7">
              <DatepickerQuickfilter
                v-on:datePickStart="datePickStart = $event"
                v-on:datePickEnd="datePickEnd = $event"
              />
            </b-col>
          </b-row>
          <b-row align-v="start" v-if="filteredOptions.length !== 0">
            <b-col cols="2" class="generalOverview ml-3 pr-2 pb-1" v-if="isVacationEnabled">
              <b-form-text
                >{{ $t('timetracking.targetWorkingHours') + ':' }}<br /><strong>{{
                  targetWorkingHours
                }}</strong></b-form-text
              >
            </b-col>
            <b-col cols="2" class="generalOverview ml-2 pr-2 pb-1" v-if="filteredOptions.length !== 0">
              <b-form-text
                >{{ $t('timetracking.workingHours') + ':' }}<br /><strong>{{ workDuration }}</strong>
              </b-form-text>
            </b-col>
            <b-col
              cols="2"
              class="customerOverview pr-2 pb-1"
              :class="index < 3 ? 'ml-2' : index === 3 ? 'ml-3 mt-2' : 'ml-2 mt-2'"
              v-for="(obj, index) in workDurationPerCustomers"
              :key="obj.customerName"
            >
              <b-form-text
                >{{ obj.customerName + ':' }}<br /><strong>{{
                  durationInMinutesToTime(obj.workingDuration)
                }}</strong></b-form-text
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="5" id="runningTicketHolder" class="mr-3">
          <b-alert show variant="info" class="p-2 mb-0" v-if="ttuStopwatch.status === 'CREATED'">
            <b-container>
              <b-row class="mb-3">
                <b-skeleton width="85%" />
              </b-row>
              <b-row class="mb-3">
                <b-skeleton width="100%" />
              </b-row>
              <b-row>
                <b-col class="pl-0">
                  <b-skeleton width="150px" class="mt-2" />
                </b-col>
                <b-col>
                  <b-skeleton type="button" width="100%" />
                </b-col>
                <b-col class="pr-0">
                  <b-skeleton type="button" width="100%" />
                </b-col>
              </b-row>
            </b-container>
          </b-alert>
          <b-alert
            show
            :variant="hasRunningTtuAndStartIsNotToday ? 'warning' : 'info'"
            class="p-2 mb-0"
            v-if="ttuStopwatch.status === 'RUNNING' || ttuStopwatch.status === 'SAVING'"
          >
            <b-overlay :show="ttuStopwatch.status === 'SAVING'" no-wrap />
            <b-container>
              <b-row>
                <b-breadcrumb
                  id="runningTimeTrackingUnitBreadcrumb"
                  :items="runningTimeTrackingUnitBreadcrumb"
                  class="w-100 p-0"
                />
              </b-row>
              <b-row>
                <b-form-input
                  id="ttuCommentInAlert"
                  :class="hasRunningTtuAndStartIsNotToday ? 'mb-3 warning' : 'mb-3 info'"
                  v-model="runningTimeTrackingUnit.comment"
                  type="text"
                  :placeholder="$t('timetracking.add-comment')"
                  size="sm"
                  @change="saveTtuComment"
                />
              </b-row>
              <b-row>
                <b-col class="px-0" xl>
                  <span class="mr-3">
                    <b-icon icon="calendar3" aria-hidden="true" class="mr-1" />
                    {{ $date(runningTimeTrackingUnit.start.date).format('DD.MM.') }}
                  </span>
                  <span class="mr-3">
                    <b-icon icon="clock" aria-hidden="true" class="mr-1" />
                    {{ runningTimeTrackingUnit.start.time }}
                  </span>
                  <span>
                    <b-icon
                      icon="hourglass-split"
                      :animation="!modalControl.paused ? 'fade' : ''"
                      aria-hidden="true"
                      class="mr-2"
                      :rotate="modalControl.paused ? 90 : 0"
                    />
                    <DayjsTimer
                      :start="runningTimeTrackingUnit.startDate"
                      :flashing="false"
                      suffix=" h"
                      :break-time="runningTimeTrackingUnit.breakDurationInMinutes"
                      @updateRunningTtuTime="updateRunningTtuTime($event)"
                    />
                  </span>
                </b-col>

                <!-- only show Pause/Resume buttons for a TTU that started on the current day -->
                <!-- this is because the "break time" isn't persisted overnight or over to a new session -->
                <b-col v-if="hasRunningTtuAndStartIsToday && !modalControl.paused" cols="3">
                  <b-button @click="pauseTimeTrackingUnit" class="running-buttons" variant="outline-info" size="sm">
                    <b-icon icon="pause-circle" aria-hidden="true" class="mr-2" />
                    {{ $t('timetracking.running-ttu.pause') }}
                  </b-button>
                </b-col>
                <b-col v-if="hasRunningTtuAndStartIsToday && modalControl.paused" cols="3">
                  <b-button
                    @click="restartTimeTrackingUnit"
                    class="running-buttons"
                    variant="outline-primary"
                    size="sm"
                  >
                    <b-icon icon="play-circle" aria-hidden="true" class="mr-2" />
                    {{ $t('timetracking.running-ttu.resume') }}
                  </b-button>
                </b-col>

                <b-col v-if="hasRunningTtuAndStartIsNotToday" class="px-0" cols="6">
                  <b-button variant="warning" @click="editRunningTtuFromPreviousDay" class="running-buttons" size="sm">
                    <b-icon icon="exclamation-circle" aria-hidden="true" class="mr-2" />
                    {{ $t('timetracking.running-ttu.save-at-midnight') }}
                  </b-button>
                </b-col>
                <b-col v-else class="px-0" cols="3">
                  <b-button
                    variant="info"
                    :disabled="modalControl.paused"
                    @click="finishTimeTrackingUnit(null)"
                    class="running-buttons"
                    size="sm"
                  >
                    <b-icon icon="check-circle" aria-hidden="true" class="mr-2" />
                    {{ $t('timetracking.running-ttu.save') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-alert>
        </b-col>
      </b-row>
    </b-container>

    <!-- Main table element -->
    <b-table
      id="timetracking-datatable"
      show-empty
      :empty-text="$t('general.table.empty-text')"
      :empty-filtered-text="$t('general.table.empty-text')"
      small
      stacked="lg"
      hover="hover"
      :items="filteredOptions"
      :fields="fields"
      :current-page="dataTableControls.currentPage"
      :per-page="dataTableControls.perPage"
      :sort-by="dataTableControls.sortBy"
      :busy="dataTableControls.busy"
      :sort-desc="true"
      striped
      fixed
      responsive
      @filtered="onFiltered"
      @row-selected="onRowSelect"
      ref="timeTrackingUnitTable"
      head-variant="light"
    >
      <template #table-colgroup="scope">
        <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-2" />
          <strong>{{ $t('general.table.loading') }}</strong>
        </div>
      </template>
      <!-- generic cell slot template to add tooltips to truncated text cells in datatables -->
      <template v-slot:cell()="row">
        <span v-ellipsis:bottom="row.value">{{ row.value }}</span>
      </template>
      <template v-slot:cell(startDate)="row">
        <div>{{ $date(row.item.startDate).format('DD.MM.YYYY') }}</div>
      </template>
      <template v-slot:cell(start.time)="row">
        <div>{{ $date(row.item.startDate).format('HH:mm') }}</div>
      </template>
      <template v-slot:cell(end.time)="row">
        <div>{{ row.item.endDate ? $date(row.item.endDate).format('HH:mm') : '' }}</div>
      </template>
      <template v-slot:cell(breakDurationInMinutes)="row">
        <div :class="{ 'text-black-30': row.value === '00:00' }">{{ row.value }}</div>
      </template>
      <template v-slot:cell(durationInMinutes)="row">
        <div>{{ row.value !== '00:00' ? row.value : '' }}</div>
      </template>
      <template v-slot:cell(ticket.ticketId)="data">
        <b-link
          v-if="data.item.ticket.project.jiraBaseUrl"
          title="Open this ticket in Jira"
          target="_blank"
          :href="getTicketUrl(data.item)"
          v-ellipsis:bottom="data.value"
        >
          {{ data.item.ticket.ticketId }}
        </b-link>
        <span v-else>{{ data.item.ticket.ticketId }}</span>
      </template>
      <template v-slot:cell(status)="row">
        <b-badge :variant="row.value === 'RUNNING' ? 'warning' : 'secondary'">{{ row.value }}</b-badge>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          @click="openEditModal(row.item)"
          size="xs"
          class="mr-1"
          variant="outline-secondary"
          :disabled="isTtuEditButtonDisabled(row.item)"
          :title="getEditButtonTitle(row.item)"
        >
          <b-icon icon="pencil" aria-hidden="true" />
        </b-button>
        <b-button
          @click="openDeleteModal('ttu', row.item)"
          size="xs"
          variant="outline-danger"
          :disabled="ttuDeleteButtonDisabled(row.item)"
          :title="getDeleteButtonTitle(row.item)"
        >
          <b-icon icon="trash" aria-hidden="true" />
        </b-button>
      </template>
    </b-table>
    <div class="overflow-auto mb-4">
      <b-pagination
        v-model="dataTableControls.currentPage"
        :total-rows="filteredOptions.length"
        :per-page="dataTableControls.perPage"
        class="mt-2"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep as _cloneDeep, isEmpty as _isEmpty } from 'lodash'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { VueAutosuggest } from 'vue-autosuggest'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { StringValidationUtil } from '@/util/StringValidationUtil'
import { debounce } from 'vue-debounce'
import { LocalDate, LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import DayjsTimer from '@/components/DayjsTimer'
import Datepicker from '@/components/GoldflamDatepicker'
import DatepickerQuickfilter from '@/components/DatepickerQuickfilter'
import { UserUtil } from '@/util/UserUtil'
import { StaticSelectOpts } from '@/constants/StaticSelectOpts'

export default {
  name: 'MainTimetracking',
  mixins: [ApiMixin, NotificationMixin],
  components: { VueAutosuggest, VueTypeaheadBootstrap, DayjsTimer, Datepicker, DatepickerQuickfilter },
  data() {
    return {
      objectModels: {
        break: {
          startTimeHours: '',
          startTimeMinutes: '',
          durationInMinutes: ''
        },
        timeTrackingUnit: {
          startDate: null,
          start: {
            date: '',
            time: ''
          },
          endDate: null,
          end: {
            date: '',
            time: ''
          },
          breakDurationInMinutes: null,
          comment: '',
          status: null,
          matchingTimeTrackingUnitId: '',
          ticket: {
            id: '',
            name: '',
            ticketId: '',
            timeTrackingUnitId: '',
            project: {
              id: '',
              fullName: '',
              customer: {
                fullName: ''
              }
            },
            parentTicketId: null
          }
        },
        ticket: {
          id: '',
          name: '',
          ticketId: '',
          project: {
            id: '',
            fullName: '',
            customer: {
              fullName: ''
            }
          }
        }
      },
      query: '',
      ticketSearch: '',
      items: [],
      dataTableControls: {
        selected: '',
        totalRows: 1,
        currentPage: this.$route.params.pageNumber ? this.$route.params.pageNumber : 1,
        perPage: 50,
        sortBy: 'startDate',
        busy: false
      },
      modalControl: {
        paused: false,
        showCreationModal: false,
        showEditModal: false,
        delete: {
          show: false,
          type: ''
        },
        showCreateTicketModal: false
      },
      showEditTicketAlertNew: false,
      showEditTicketAlertExisting: false,
      showEditTicketAlertChanged: false,
      numberOfAssociatedTTUs: 0,
      existingTicketWithSameUid: null,
      break: {},
      datePickStart: null,
      datePickEnd: null,
      targetWorkingHours: '00:00',
      timeTrackingUnit: {},
      existingTicket: {},
      recentTicketsFilterUserId: -1,
      activeUserList: [],
      ticketSuggestionQuery: '',
      parentTicketSuggestionQuery: '',
      ticketIdSuggestionQuery: '',
      selectedTicket: null,
      ticketNameSuggestionQuery: '',
      ticketSuggestionList: [],
      ticketIdSuggestionList: [],
      ticketNameSuggestionList: [],
      parentTicketSuggestions: [],
      oldTicketName: '',
      projectSuggestionQuery: '',
      projectSuggestionList: [],
      recentTickets: [],
      recentProjects: [],
      statusOptions: StaticSelectOpts.timeTrackingUnitStatusOptions,
      selectedRows: [],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      ttuStopwatch: {
        status: ''
      },
      runningTimeTrackingUnit: null,
      runningTimeTrackingUnitTime: null
    }
  },
  created() {
    // initialize some objects with clones from objectModels
    this.timeTrackingUnit = _cloneDeep(this.objectModels.timeTrackingUnit)
    this.existingTicket = _cloneDeep(this.objectModels.ticket)
    this.break = _cloneDeep(this.objectModels.break)
    // TIM-356 set the default datatable filters as early as possible to avoid
    // multiple re-computations of the computed prop "filteredOptions"
    this.setDatepickerFiltersToCurrentWeek()
  },
  mounted() {
    // set datatable busy and fetch data
    console.debug('Initial table data loading...')
    this.dataTableControls.busy = true
    this.fetchTableData().then(() => {
      this.dataTableControls.totalRows = this.items.length
      this.dataTableControls.busy = false
      if (this.runningTimeTrackingUnit && this.ttuStopwatch.status === '') {
        this.ttuStopwatch.status = 'RUNNING'
      }
    })
    this.getAllActiveUsers()
  },
  watch: {
    runningTimeTrackingUnit: function (newRunningTimetrackingUnit) {
      if (newRunningTimetrackingUnit !== false && this.ttuStopwatch.status === 'CREATED') {
        this.ttuStopwatch.status = 'RUNNING'
      }
    },
    'timeTrackingUnit.start.date': function (newVal) {
      this.timeTrackingUnit.end.date = newVal
    },
    'timeTrackingUnit.end.time': function (newVal) {
      if (this.modalControl.showCreationModal || this.modalControl.showEditModal) {
        if (newVal === null || newVal === '') {
          this.timeTrackingUnit.status = 'RUNNING'
        } else {
          this.timeTrackingUnit.status = 'FINISHED'
        }
      }
    },
    datePickStart: function (newDatePickStart, oldDatePickStart) {
      if (this.$dayjs(newDatePickStart).isAfter(this.datePickEnd, 'day')) {
        this.datePickEnd = newDatePickStart
      }
      if (newDatePickStart !== oldDatePickStart) {
        // TIM-419 update targetWorkingHours at every property change
        this.fetchTargetWorkingHours()
        // TIM-468 reload TTU's on a new start date
        this.reloadTable()
      }
    },
    datePickEnd: function (newDatePickEnd, oldDatePickEnd) {
      if (newDatePickEnd !== oldDatePickEnd) {
        // TIM-419 update targetWorkingHours at every property change
        this.fetchTargetWorkingHours()
        // TIM-468 reload TTU's on a new end date
        this.reloadTable()
      }
    },
    parentTicketSuggestionQuery: function (newVal) {
      if (!newVal || newVal === '') {
        this.timeTrackingUnit.ticket.parentTicketId = null
      }
    }
  },
  computed: {
    checkExistingTicket() {
      return this.existingTicket && this.existingTicket.id !== ''
    },
    modalDisabled() {
      let isModalFilled =
        (this.ticketSuggestionQuery !== '' || this.timeTrackingUnit.ticket.name) &&
        this.timeTrackingUnit.start.date &&
        this.timeTrackingUnit.start.time &&
        this.timeTrackingUnit.end.date &&
        this.timeTrackingUnit.end.time &&
        this.timeTrackingUnit.status &&
        this.isTicketUidValid
      if (this.timeTrackingUnit.status === 'RUNNING') {
        isModalFilled =
          (this.checkExistingTicket || this.timeTrackingUnit.ticket.name) &&
          this.timeTrackingUnit.start.date &&
          this.timeTrackingUnit.start.time &&
          this.isTicketUidValid
      }
      return !isModalFilled
    },
    editModalDisabled() {
      let isModalFilled =
        ((this.projectSuggestionQuery !== '' && this.ticketNameSuggestionQuery !== '' && this.isEditTicketUidValid) ||
          (this.ticketSuggestionQuery !== '' && this.checkExistingTicket)) &&
        this.timeTrackingUnit.start.date &&
        this.timeTrackingUnit.start.time &&
        this.timeTrackingUnit.end.date &&
        this.timeTrackingUnit.end.time &&
        this.timeTrackingUnit.status
      if (this.timeTrackingUnit.status === 'RUNNING') {
        isModalFilled =
          ((this.projectSuggestionQuery !== '' && this.ticketNameSuggestionQuery !== '' && this.isEditTicketUidValid) ||
            (this.ticketSuggestionQuery !== '' && this.checkExistingTicket)) &&
          this.timeTrackingUnit.start.date &&
          this.timeTrackingUnit.start.time
      }
      return !isModalFilled
    },
    runningTimeTrackingUnitBreadcrumb() {
      if (this.runningTimeTrackingUnit && this.runningTimeTrackingUnit.ticket.name !== '') {
        return [
          { text: this.runningTimeTrackingUnit.ticket.project.customer.fullName },
          { text: this.runningTimeTrackingUnit.ticket.project.fullName },
          {
            text: (this.runningTimeTrackingUnit.ticket.ticketId + ' ' + this.runningTimeTrackingUnit.ticket.name).trim()
          }
        ]
      }
      return []
    },
    editTimetrackingUnitBreadcrumb() {
      return [
        { text: this.timeTrackingUnit.ticket.project.customer.fullName },
        { text: this.timeTrackingUnit.ticket.project.fullName }
      ]
    },
    fields() {
      return [
        { key: 'startDate', label: this.$t('timetracking.date'), sortable: true, width: '100px' },
        { key: 'start.time', label: this.$t('timetracking.start'), sortable: false, width: '55px' },
        { key: 'end.time', label: this.$t('timetracking.end'), sortable: false, width: '55px' },
        {
          key: 'breakDurationInMinutes',
          label: this.$t('timetracking.table-headers.break-duration'),
          sortable: false,
          width: '55px',
          formatter: 'durationInMinutesToTime'
        },
        {
          key: 'durationInMinutes',
          label: this.$t('timetracking.duration'),
          sortable: false,
          width: '55px',
          formatter: 'durationInMinutesToTime'
        },
        { key: 'ticket.ticketId', label: this.$t('general.ticketId'), sortable: true, width: '100px' },
        { key: 'ticket.name', label: this.$t('general.ticketName'), sortable: true, width: 'auto' },
        { key: 'comment', label: this.$t('timetracking.comment'), sortable: false, width: 'auto' },
        { key: 'ticket.project.fullName', label: this.$t('timetracking.project'), sortable: true, width: '12%' },
        {
          key: 'ticket.project.customer.fullName',
          label: this.$t('timetracking.customer'),
          sortable: true,
          width: '10%'
        },
        { key: 'status', label: 'Status', sortable: true, width: '80px' },
        { key: 'actions', label: 'TTU', sortable: false, width: '65px' }
      ]
    },
    filteredOptions() {
      if (!this.items) {
        return []
      }
      return this.items.filter(item => {
        // copy b-tables :filter behaviour
        let stringifiedItem = JSON.stringify(item, (key, value) => (key.startsWith('_') ? undefined : value))
        return (
          (!this.datePickStart || item.startDate > this.datePickStart) &&
          (!this.datePickEnd || item.startDate < this.datePickEnd + 'T23:59:59') &&
          stringifiedItem.includes(this.dataTableControls.selected)
        )
      })
    },
    suggestions() {
      const suggestions = []
      this.filteredOptions.forEach(option => {
        const { customer, project, ticket } = option
        if (customer) {
          suggestions.push(customer.fullName)
        }
        if (project) {
          suggestions.push(project.fullName)
        }
        if (ticket) {
          suggestions.push(ticket.name)
        }
      })
      const filteredSuggestions = suggestions.filter(item => {
        return item.toLowerCase().startsWith(this.query.toLowerCase())
      })
      return [{ data: [...new Set(filteredSuggestions)] }]
    },
    deleteModalTitle() {
      if (this.modalControl.delete.type === 'ttu') {
        return this.$t('timetracking.modals.deleteModal.deleteTimeTrackingUnitTitle')
      }
      return this.$t('timetracking.modals.deleteModal.deleteTicketTitle')
    },
    isTicketUidValid() {
      return StringValidationUtil.isValidTicketUid(this.timeTrackingUnit.ticket.ticketId)
    },
    isEditTicketUidValid() {
      return StringValidationUtil.isValidTicketUid(this.ticketIdSuggestionQuery)
    },
    workDuration() {
      let totalDuration = 0
      this.filteredOptions.forEach(option => {
        // durationInMinutes already contains the net duration excluding the break duration
        totalDuration += option.durationInMinutes
        if (option.status === 'RUNNING' && this.runningTimeTrackingUnitTime !== null) {
          // the running ttu is in the filtered options, add its duration
          totalDuration += this.runningTimeTrackingUnitTime.asMinutes()
        }
      })
      return LocalDateTimeFormatter.durationInMinutesToTime(totalDuration)
    },
    workDurationPerCustomers() {
      // get all customers that are present in the filtered ttus
      const customers = this.filteredOptions
        .map(ttu => {
          return ttu.ticket.project.customer.fullName
        })
        .filter((customer, index, self) => self.indexOf(customer) === index)

      // compute for each customer the duration of the ttus that the user has worked on
      return customers.map(customer => {
        const ttusForCustomer = this.filteredOptions.filter(ttu => ttu.ticket.project.customer.fullName === customer)
        const duration = ttusForCustomer.reduce((acc, ttu) => {
          if (ttu.status === 'RUNNING' && this.runningTimeTrackingUnitTime !== null) {
            acc += this.runningTimeTrackingUnitTime.asMinutes()
          } else {
            acc += ttu.durationInMinutes
          }

          return acc
        }, 0)
        return { customerName: customer, workingDuration: duration }
      })
    },
    hasRunningTtuAndStartIsNotToday() {
      if (this.runningTimeTrackingUnit && this.ttuStopwatch.status === 'RUNNING') {
        return this.runningTimeTrackingUnit.start.date !== LocalDate.today()
      } else {
        return false
      }
    },
    hasRunningTtuAndStartIsToday() {
      return !this.hasRunningTtuAndStartIsNotToday
    }
  },
  methods: {
    isRunningTTUInCurrentTable() {
      let runningTTUInItems = this.items.filter(
        ttu => ttu.matchingTimeTrackingUnitId === this.runningTimeTrackingUnit.matchingTimeTrackingUnitId
      )
      return !_isEmpty(runningTTUInItems)
    },
    fetchTargetWorkingHours() {
      let start
      let end
      if (this.datePickStart !== null && this.datePickEnd !== null) {
        start = this.datePickStart
        end = this.datePickEnd
      } else {
        start = LocalDateTimeFormatter.toDate(this.filteredOptions[0].startDate)
        end = LocalDateTimeFormatter.toDate(this.filteredOptions[this.filteredOptions.length - 1].startDate)
      }
      let self = this
      const userId = self.$store.getters.getCurrentUser.id
      this.getRequest(
        `/users/${userId}/timesheet/target_working_minutes?start=${start}&end=${end}`,
        new RequestConfig().onSuccess(res => {
          self.targetWorkingHours = LocalDateTimeFormatter.durationInMinutesToTime(res.data)
        })
      )
    },
    setDatepickerFiltersToCurrentWeek() {
      this.datePickStart = LocalDate.mondayInCurrentWeek()
      this.datePickEnd = LocalDate.fridayInCurrentWeek()
    },
    ticketDropdownSerializer(ticket) {
      return (
        ticket.ticketId +
        ' ' +
        ticket.name +
        ' (' +
        ticket.project.fullName +
        ' / ' +
        ticket.project.customer.fullName +
        ')'
      )
    },
    editTicketDropdownSerializer(ticket) {
      return ticket.ticketId + ' ' + ticket.name
    },
    onlyTicketName(ticket) {
      return ticket.name
    },
    toggleTicketTabs(tab) {
      this.ticketSuggestionList = this.recentTickets
      if (this.$refs.searchTicketsDropdown) {
        this.$refs.searchTicketsDropdown.inputValue = ''
      }
      if (this.$refs.recentTicketsDropdown) {
        this.$refs.recentTicketsDropdown.inputValue = ''
      }
      if (this.$refs.projectDropdown) {
        this.$refs.projectDropdown.inputValue = ''
      }
      this.ticketSuggestionQuery = ''
      this.parentTicketSuggestionQuery = ''
      this.projectSuggestionQuery = ''
      if (tab === 'recent') {
        this.recentTicketsFilterUserId = -1
      } else if (tab === 'existing') {
        this.recentTicketsFilterUserId = this.$store.getters.getCurrentUser.id
      }
      this.getRecentTickets()
      this.getRecentlyUsedProjects()
      this.clearExistingTicket()
      this.clearTicketInCurrentTTU()
    },
    toggleEditTicketTabs(tab) {
      if (tab === 'recent') {
        this.recentTicketsFilterUserId = -1
      } else if (tab === 'existing') {
        this.recentTicketsFilterUserId = this.$store.getters.getCurrentUser.id
      }
      this.getRecentTickets()
      this.getRecentlyUsedProjects()
      this.clearExistingTicket()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.dataTableControls.totalRows = filteredItems.length
    },
    selectHandler(item) {
      if (!item) {
        this.dataTableControls.selected = ''
      } else {
        this.dataTableControls.selected = item.item
      }
    },
    clearFilter() {
      this.query = ''
      this.unselectIfQueryEmpty()
      this.setDatepickerFiltersToCurrentWeek()
    },
    isTtuEditButtonDisabled(ttu) {
      let disabled_statuses = ['REVIEWED', 'BILLED']
      return ttu.reviewTimeTrackingUnit !== null && disabled_statuses.includes(ttu.reviewTimeTrackingUnit.reviewStatus)
    },
    ttuDeleteButtonDisabled(ttu) {
      return this.isTtuEditButtonDisabled(ttu) || ttu.status === 'RUNNING'
    },
    getEditButtonTitle(ttu) {
      return this.isTtuEditButtonDisabled(ttu) ? this.$t('timetracking.edit-button-disabled') : ''
    },
    getDeleteButtonTitle(ttu) {
      if (this.ttuDeleteButtonDisabled(ttu)) {
        if (ttu.status === 'RUNNING') {
          return this.$t('timetracking.delete-button-disabled-running')
        } else {
          return this.$t('timetracking.delete-button-disabled')
        }
      } else {
        return ''
      }
    },
    fetchTableData() {
      let self = this
      this.fetchRunningTTU()
      return this.getRequest(
        `/table/user_ttus?start=${self.datePickStart}&end=${self.datePickEnd}`,
        new RequestConfig()
          .onSuccess(res => {
            self.items = res.data
          })
          .onError(() => {
            self.items = []
          })
      )
    },
    fetchRunningTTU() {
      let self = this
      return this.getRequest(
        `/table/running_user_ttu`,
        new RequestConfig().onSuccess(res => {
          self.setStartAndEndOfRunningTTU(res.data)
        })
      )
    },
    setStartAndEndOfRunningTTU(runningTtu) {
      if (runningTtu !== null && !_isEmpty(runningTtu)) {
        runningTtu.start = {
          date: LocalDateTimeFormatter.toDate(runningTtu.startDate),
          time: LocalDateTimeFormatter.toTime(runningTtu.startDate)
        }
        runningTtu.end = {
          date: '',
          time: ''
        }
        this.runningTimeTrackingUnit = runningTtu
      } else {
        this.runningTimeTrackingUnit = null
      }
    },
    onRowSelect(items) {
      this.selectedRows = items
    },
    openCreationModal() {
      // reset modal
      this.resetCreationModal()
      // show modal
      this.modalControl.showCreationModal = true
      // reset Ticket input fields in all tabs
      this.toggleTicketTabs('existing')
      // load recent user tickets
      this.getRecentTickets()
      // load recent projects
      this.getRecentlyUsedProjects()
      // clear edit-modal queries
      this.projectSuggestionQuery = ''
      this.ticketIdSuggestionQuery = ''
    },
    setProjectAndLoadParentTicketSuggestions(event) {
      this.timeTrackingUnit.ticket.project = event
      if (this.timeTrackingUnit.ticket.project.ticketIdPrefix !== null) {
        this.timeTrackingUnit.ticket.ticketId = this.timeTrackingUnit.ticket.project.ticketIdPrefix
        if (this.timeTrackingUnit.ticket.project.ticketIdPrefix !== '#') {
          this.timeTrackingUnit.ticket.ticketId += '-'
        }
      }
      if (this.timeTrackingUnit.ticket.project.id) {
        this.loadAllOpenParentTicketSuggestionsForProject(this.timeTrackingUnit.ticket.project.id)
      }
    },
    loadAllOpenParentTicketSuggestionsForProject(projectId) {
      let self = this
      return this.getRequest(
        `/tickets/parentTicketSuggestions?onlyOpenProjects=${true}&projectId=${projectId}`,
        new RequestConfig().onSuccess(res => {
          self.parentTicketSuggestions = res.data
        })
      )
    },
    loadAllParentTicketSuggestionsForProject(ticketId, projectId) {
      let self = this
      return this.getRequest(
        `/tickets/parentTicketSuggestions?excludedTicketId=${ticketId}&projectId=${projectId}`,
        new RequestConfig().onSuccess(res => {
          self.parentTicketSuggestions = res.data

          let foundOne = false
          res.data.forEach(suggestion => {
            if (suggestion.id === self.timeTrackingUnit.ticket.parentTicketId) {
              self.parentTicketSuggestionQuery =
                (suggestion.ticketId ? suggestion.ticketId + ' ' : '') + suggestion.name
              foundOne = true
            }
          })
          if (!foundOne) {
            self.parentTicketSuggestionQuery = ''
          }
        })
      )
    },
    getRecentTickets() {
      let self = this
      let apiEndpoint = '/tickets/recent'
      if (this.recentTicketsFilterUserId >= 0) {
        if (this.$refs.recentTicketsDropdown) {
          this.ticketSuggestionList = []
          this.$refs.recentTicketsDropdown.inputValue = ''
          this.ticketSuggestionQuery = ''
          this.clearExistingTicket()
        }
        apiEndpoint += '?userId=' + this.recentTicketsFilterUserId
      }
      this.getRequest(
        apiEndpoint,
        new RequestConfig().onSuccess(res => {
          self.recentTickets = res.data
          self.ticketSuggestionList = res.data
        })
      )
    },
    getRecentlyUsedProjects() {
      if (this.recentTickets.length <= 0) {
        this.getRecentTickets()
      }
      let filteredProjectsFromTickets = this.recentTickets.map(ticket => {
        return ticket.project
      })
      let uniqueProjectSuggestions = this.removeDuplicates(filteredProjectsFromTickets, 'fullName')
      this.projectSuggestionList = uniqueProjectSuggestions
      this.recentProjects = uniqueProjectSuggestions
    },
    removeDuplicates(myArr, prop) {
      return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
      })
    },
    projectSuggestionDebouncer() {
      this.projectSuggestionList = []
      this.getProjectSuggestionsDebounced()
    },
    getProjectSuggestionsDebounced: debounce(function () {
      this.getProjectSuggestions()
    }, 400),
    getProjectSuggestions() {
      let self = this
      const typeaheadInput = this.projectSuggestionQuery
      if (typeaheadInput.length >= 3) {
        this.getRequest(
          `/projects/search?query=${typeaheadInput}&onlyOpen=true`,
          new RequestConfig().onSuccess(res => {
            self.projectSuggestionList = res.data
          })
        )
      } else if (typeaheadInput.length === 0) {
        self.projectSuggestionList = self.recentProjects
        // TODO: the whole object should be cleared, but for now we only delete the project id if a project has previously been selected
        self.timeTrackingUnit.ticket.project.id = ''
      }
    },
    ticketSuggestionDebouncer() {
      this.ticketSuggestionList = []
      this.getTicketSuggestionDebounced()
    },
    getTicketSuggestionDebounced: debounce(function () {
      this.getTicketSuggestions()
    }, 400),
    getTicketSuggestions() {
      let self = this
      const typeaheadInput = this.ticketSuggestionQuery
      if (typeaheadInput.length >= 3) {
        this.getRequest(
          `/tickets/search_fulltext?query=${encodeURIComponent(typeaheadInput)}&onlyOpen=true`,
          new RequestConfig().onSuccess(res => {
            self.ticketSuggestionList = res.data
          })
        )
      } else if (typeaheadInput.length === 0) {
        self.ticketSuggestionList = self.recentTickets
        // TODO: the whole object should be cleared, but for now we only delete the ticket id if a ticket has previously been selected
        self.existingTicket.id = ''
      }
    },
    fillProjectAndUpdateSuggestionList(event) {
      this.timeTrackingUnit.ticket.project = event
      this.ticketIdSuggestionQuery = ''
      this.ticketNameSuggestionQuery = ''
      this.parentTicketSuggestionQuery = ''
      if (this.ticketIdSuggestionQuery !== '') {
        this.getEditTicketIdSuggestions()
      }
      this.loadAllOpenParentTicketSuggestionsForProject(event.id)
    },
    editTicketSuggestionDebouncer(eventCase) {
      this.ticketSuggestionList = []
      this.getEditTicketSuggestionDebounced(eventCase)
    },
    getEditTicketSuggestionDebounced: debounce(function (eventCase) {
      if (eventCase === 'UID') {
        this.getEditTicketIdSuggestions(eventCase)
      } else if (eventCase === 'NAME') {
        this.getEditTicketNameSuggestions(eventCase)
      }
    }, 400),
    getEditTicketIdSuggestions() {
      let self = this
      const projectId = this.timeTrackingUnit.ticket.project.id
      const typeaheadInput = this.ticketIdSuggestionQuery
      if (typeaheadInput.length >= 2) {
        this.getRequest(
          `/tickets/search?openOnly=true&queryFilter=uid&projectId=${encodeURIComponent(
            projectId
          )}&query=${encodeURIComponent(typeaheadInput)}`,
          new RequestConfig().onSuccess(res => {
            self.hideEditTicketAlerts()
            if (res.data.length === 0) {
              if (this.isEditTicketUidValid) {
                this.showEditTicketAlertNew = true
              }
            } else {
              self.ticketIdSuggestionList = res.data
              let matchingUidExists = false
              let position = 0
              self.ticketIdSuggestionList.forEach((ticket, index) => {
                if (ticket.ticketId === self.ticketIdSuggestionQuery) {
                  matchingUidExists = true
                  position = index
                }
              })
              if (matchingUidExists) {
                this.showEditTicketAlertExisting = true
                this.existingTicketWithSameUid = this.ticketIdSuggestionList[position]
                self.getNumberOfAssociatedTTUsForTicket(this.existingTicketWithSameUid.id).then(res => {
                  this.numberOfAssociatedTTUs = res.data
                })
              } else {
                if (this.isEditTicketUidValid) {
                  this.showEditTicketAlertNew = true
                }
              }
            }
          })
        )
      } else if (typeaheadInput.length === 0) {
        self.ticketIdSuggestionList = []
        this.hideEditTicketAlerts()
      }
    },
    hideEditTicketAlerts() {
      this.showEditTicketAlertNew = false
      this.showEditTicketAlertExisting = false
      this.showEditTicketAlertChanged = false
      this.numberOfAssociatedTTUs = 0
    },
    getEditTicketNameSuggestions() {
      let self = this
      const projectId = this.timeTrackingUnit.ticket.project.id
      const typeaheadInput = this.ticketNameSuggestionQuery
      if (typeaheadInput.length >= 3) {
        this.getRequest(
          `/tickets/search?openOnly=true&queryFilter=name&projectId=${encodeURIComponent(
            projectId
          )}&query=${encodeURIComponent(typeaheadInput)}`,
          new RequestConfig().onSuccess(res => {
            self.ticketNameSuggestionList = res.data
            if (res.data.length === 0 && !self.showEditTicketAlertNew) {
              const oldTicketName = this.timeTrackingUnit.ticket.name
              if (oldTicketName !== self.ticketNameSuggestionQuery) {
                self.showEditTicketAlertChanged = true
                self.getNumberOfAssociatedTTUsForTicket(this.timeTrackingUnit.ticket.id).then(res => {
                  this.numberOfAssociatedTTUs = res.data
                })
              }
            }
          })
        )
      } else if (typeaheadInput.length === 0) {
        self.ticketNameSuggestionList = []
      }
    },
    getNumberOfAssociatedTTUsForTicket(ticketId) {
      return this.getRequest(`/tickets/${ticketId}/ttu_count`)
    },
    setTicketNameAndUidToQueryDebouncer(event) {
      this.ticketIdSuggestionQuery = ''
      this.ticketNameSuggestionQuery = ''
      this.showEditTicketAlertChanged = false
      this.setTicketNameToQueryDebounced(event)
      this.loadAllParentTicketSuggestionsForProject(event.id, event.project.id)
    },
    setTicketNameToQueryDebounced: debounce(function (event) {
      this.setTicketNameAndUidToQuery(event)
    }, 0),
    setTicketNameAndUidToQuery(event) {
      const oldTicketId = this.timeTrackingUnit.ticket.id
      this.timeTrackingUnit.ticket = event
      this.timeTrackingUnit.ticket.id = oldTicketId
      this.ticketNameSuggestionQuery = event.name
      this.ticketIdSuggestionQuery = event.ticketId
    },
    updateRunningTtuTime(event) {
      this.runningTimeTrackingUnitTime = event
    },
    getAllActiveUsers() {
      this.getRequest(
        '/users?status=active',
        new RequestConfig().onSuccess(res => {
          UserUtil.sortByFullName(res.data).forEach(user => {
            this.activeUserList.push({ value: user.id, text: user.fullName })
          })
        })
      )
    },
    openEditModal(row) {
      if (row.ticket.id) {
        this.loadAllParentTicketSuggestionsForProject(row.ticket.id, row.ticket.project.id)
      }
      // reset TTU and Ticket objects
      this.resetCreationAndEditModal()
      // fill TTU object with row item
      const { ticket, startDate, endDate, breakDurationInMinutes, comment, status, matchingTimeTrackingUnitId } = row
      this.timeTrackingUnit = Object.assign(this.timeTrackingUnit, {
        startDate: startDate,
        start: {
          date: LocalDateTimeFormatter.toDate(startDate),
          time: LocalDateTimeFormatter.toTime(startDate)
        },
        comment: comment,
        status: status,
        breakDurationInMinutes: breakDurationInMinutes,
        matchingTimeTrackingUnitId: matchingTimeTrackingUnitId,
        ticket: {
          name: ticket.name,
          id: ticket.id,
          ticketId: ticket.ticketId,
          timeTrackingUnitId: matchingTimeTrackingUnitId,
          project: {
            id: ticket.project.id,
            fullName: ticket.project.fullName,
            customer: {
              id: ticket.project.customer.id,
              fullName: ticket.project.customer.fullName
            }
          },
          parentTicketId: ticket.parentTicketId
        }
      })

      // Set endDate and end.date / end.time properties if endDate is available
      if (endDate !== null && endDate !== '') {
        this.timeTrackingUnit.endDate = endDate
        this.timeTrackingUnit.end = {
          date: LocalDateTimeFormatter.toDate(endDate || LocalDate.today()),
          time: LocalDateTimeFormatter.toTime(endDate || this.getCurrentTime('end'))
        }
      }
      // Otherwise set endDate to null and end.time to '' BUT end.date to the current date so that the user doesn't
      // have to choose a date from the datepicker when deciding to finish this TTU by adding and end.time value
      // endDate will be calculated from end.date and end.time before sending the JSON to the API endpoint.
      else {
        this.timeTrackingUnit.endDate = null
        this.timeTrackingUnit.end = {
          date: LocalDate.today(),
          time: ''
        }
      }

      const projectName = this.timeTrackingUnit.ticket.project.fullName
      const customerName = this.timeTrackingUnit.ticket.project.customer.fullName
      this.projectSuggestionQuery = `${projectName} (${customerName})`
      this.ticketIdSuggestionQuery = this.timeTrackingUnit.ticket.ticketId
      this.ticketNameSuggestionQuery = this.timeTrackingUnit.ticket.name
      this.oldTicketName = this.timeTrackingUnit.ticket.name
      this.showEditTicketAlertExisting = false
      this.showEditTicketAlertNew = false
      this.showEditTicketAlertChanged = false
      this.modalControl.showEditModal = true
    },
    openDeleteModal(type, row) {
      this.timeTrackingUnit.matchingTimeTrackingUnitId = row.matchingTimeTrackingUnitId
      this.timeTrackingUnit.ticket.id = row.ticket.id
      this.modalControl.delete.show = true
      this.modalControl.delete.type = type
    },
    createTimeTrackingUnit() {
      if (this.timeTrackingUnit.status === 'RUNNING') {
        this.ttuStopwatch.status = 'CREATED'
      }
      this.copyExistingTicketObjectIfAvailable()
      this.setStartAndEndDatesForCurrentTTU()
      if (!this.timeTrackingUnit.breakDurationInMinutes) {
        this.timeTrackingUnit.breakDurationInMinutes = 0
      }
      const timeTrackingUnitJSON = this.createDtoJSON('ttu')
      let self = this
      this.postRequest(
        '/timetracking_units',
        timeTrackingUnitJSON,
        new RequestConfig()
          .onSuccess(() => {
            self.modalControl.showCreationModal = false
            self.displaySuccess(self.$t('timetracking.ttu-created-success'))
            self.reloadTable()
          })
          .onError(err => {
            self.ttuStopwatch.status = ''
            if (err.response.data.message === 'project-closed') {
              self.displayError(self.$t('errors.project-closed'))
            } else if (err.response.data.message === 'ticket-closed') {
              self.displayError(self.$t('errors.ticket-closed'))
            } else {
              self.displayError(err.response.data.message)
            }
          })
      )
    },
    finishAndCreateTimeTrackingUnit() {
      let endDateTime = LocalDateTimeFormatter.toDateTime(
        this.timeTrackingUnit.start.date,
        this.timeTrackingUnit.start.time
      )
      this.finishTimeTrackingUnit(endDateTime).then(() => {
        this.createTimeTrackingUnit()
      })
    },
    saveTtuComment() {
      let self = this
      let ttuId = this.runningTimeTrackingUnit.matchingTimeTrackingUnitId
      let ttuComment = this.runningTimeTrackingUnit.comment
      let patchPayload = { comment: ttuComment }
      this.patchRequest(
        `/timetracking_units/${ttuId}`,
        patchPayload,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('timetracking.ttu-comment-success'))
          if (this.isRunningTTUInCurrentTable()) {
            this.reloadTable()
          }
        })
      )
    },
    editTimeTrackingUnit() {
      if (this.timeTrackingUnit.status === 'RUNNING') {
        this.ttuStopwatch.status = 'CREATED'
        this.timeTrackingUnit.end.date = ''
        this.timeTrackingUnit.end.time = ''
      } else if (
        this.runningTimeTrackingUnit &&
        this.ttuStopwatch.status === 'RUNNING' &&
        this.runningTimeTrackingUnit.matchingTimeTrackingUnitId === this.timeTrackingUnit.matchingTimeTrackingUnitId
      ) {
        this.ttuStopwatch.status = 'SAVING'
      }
      this.setStartAndEndDatesForCurrentTTU()
      this.timeTrackingUnit.breakDurationInMinutes = Math.round(this.timeTrackingUnit.breakDurationInMinutes / 5) * 5
      this.timeTrackingUnit.ticket.timeTrackingUnitId = this.timeTrackingUnit.matchingTimeTrackingUnitId
      this.timeTrackingUnit.ticket.ticketId = this.ticketIdSuggestionQuery
      this.timeTrackingUnit.ticket.name = this.ticketNameSuggestionQuery
      this.copyExistingTicketObjectIfAvailableWithoutId()
      const timeTrackingUnitJSON = this.createDtoJSON('ttu')
      const id = this.timeTrackingUnit.matchingTimeTrackingUnitId
      let self = this
      this.postRequest(
        `/timetracking_units/${id}`,
        timeTrackingUnitJSON,
        new RequestConfig().onSuccess(() => {
          if (self.ttuStopwatch.status === 'SAVING') {
            self.ttuStopwatch.status = ''
          }
          self.displaySuccess(self.$t('timetracking.ttu-edited-success'))
          self.reloadTable()
        })
      )
      this.modalControl.showEditModal = false
    },
    finishTimeTrackingUnit(endDate) {
      this.ttuStopwatch.status = 'SAVING'

      // clone the entire object with lodash cloneDeep
      let timeTrackingUnit_copy = _cloneDeep(this.runningTimeTrackingUnit)
      timeTrackingUnit_copy.status = 'FINISHED'
      if (endDate !== null) {
        // in case we finish a running TTU by creating a new one by calling finishAndCreateTimeTrackingUnit()
        timeTrackingUnit_copy.endDate = endDate
      } else {
        timeTrackingUnit_copy.endDate = LocalDateTimeFormatter.toDateTime(LocalDate.today(), this.getCurrentTime('end'))
      }
      const timeTrackingUnitJSON = JSON.stringify(timeTrackingUnit_copy)
      const ttuId = timeTrackingUnit_copy.matchingTimeTrackingUnitId
      let self = this
      return this.postRequest(
        `/timetracking_units/${ttuId}`,
        timeTrackingUnitJSON,
        new RequestConfig().onSuccess(() => {
          self.ttuStopwatch.status = ''
          self.displaySuccess(self.$t('timetracking.ttu-finished-success'))
          self.reloadTable()
        })
          .onError((err) => {
            self.ttuStopwatch.status = 'RUNNING'
            self.displayError(err.response.data.message)
          })
      )
    },
    editRunningTtuFromPreviousDay() {
      // set endDate/time to startDate 23:59
      const endDate = this.runningTimeTrackingUnit.start.date
      const endTime = '23:59'
      this.runningTimeTrackingUnit.end.date = endDate
      this.runningTimeTrackingUnit.end.time = endTime
      this.runningTimeTrackingUnit.endDate = LocalDateTimeFormatter.toDateTime(endDate, endTime)
      // open edit modal with the contents from the running TTU
      this.openEditModal(this.runningTimeTrackingUnit)
      // wait 500ms and then focus the endTime input field
      setTimeout(() => {
        this.$refs.editTtuModalEndTime.focus()
      }, 500)
    },
    pauseTimeTrackingUnit() {
      let today = new Date()
      this.break.startTimeHours = today.getHours()
      this.break.startTimeMinutes = today.getMinutes()
      this.modalControl.paused = true
    },
    restartTimeTrackingUnit() {
      this.copiedTimeTrackingUnit = _cloneDeep(this.runningTimeTrackingUnit)
      this.copiedTimeTrackingUnit.breakDurationInMinutes = parseInt(this.runningTimeTrackingUnit.breakDurationInMinutes)
      let now = new Date()
      let hours = now.getHours() - this.break.startTimeHours
      this.break.durationInMinutes = 60 * hours + now.getMinutes() - this.break.startTimeMinutes
      let totalDuration = Math.round(this.break.durationInMinutes / 5) * 5
      if (this.copiedTimeTrackingUnit.breakDurationInMinutes) {
        totalDuration = this.copiedTimeTrackingUnit.breakDurationInMinutes + totalDuration
      }
      this.copiedTimeTrackingUnit.breakDurationInMinutes = totalDuration
      const timeTrackingUnitJSON = JSON.stringify(this.copiedTimeTrackingUnit)
      const id = this.copiedTimeTrackingUnit.matchingTimeTrackingUnitId
      let self = this
      this.postRequest(
        `/timetracking_units/${id}`,
        timeTrackingUnitJSON,
        new RequestConfig().onSuccess(() => {
          self.modalControl.paused = false
          self.clearBreak()
          self.displaySuccess(self.$t('timetracking.ttu-restarted-success'))
          self.reloadTable()
        })
      )
    },
    deleteTimeTackingUnit() {
      const id = this.timeTrackingUnit.matchingTimeTrackingUnitId
      let self = this
      this.deleteRequest(
        `/timetracking_units/${id}`,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('timetracking.ttu-deleted-success'))
          self.reloadTable()
        })
      )
      this.modalControl.delete.show = false
    },
    deleteTicket() {
      const ticketId = this.timeTrackingUnit.ticket.id
      let self = this
      this.deleteRequest(
        `/tickets/${ticketId}`,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('timetracking.ticket-deleted-success'))
          self.reloadTable()
        })
      )
      this.modalControl.delete.show = false
    },
    createDtoJSON(type) {
      switch (type) {
        case 'ttu':
          return JSON.stringify(this.timeTrackingUnit)
        case 'ticket':
          return JSON.stringify(this.timeTrackingUnit.ticket)
        default:
          // TODO: improve error handling
          console.debug("JSON couldn't be created")
      }
    },
    copyExistingTicketObjectIfAvailable() {
      if (this.checkExistingTicket) {
        this.timeTrackingUnit.ticket.id = this.existingTicket.id
        this.timeTrackingUnit.ticket.name = this.existingTicket.name
        this.timeTrackingUnit.ticket.ticketId = this.existingTicket.ticketId
        this.timeTrackingUnit.ticket.project.id = this.existingTicket.project.id
        this.timeTrackingUnit.ticket.project.fullName = this.existingTicket.project.fullName
        this.timeTrackingUnit.ticket.project.customer.fullName = this.existingTicket.project.customer.fullName
      }
    },
    copyExistingTicketObjectIfAvailableWithoutId() {
      if (this.checkExistingTicket) {
        this.timeTrackingUnit.ticket.name = this.existingTicket.name
        this.timeTrackingUnit.ticket.ticketId = this.existingTicket.ticketId
        this.timeTrackingUnit.ticket.project.id = this.existingTicket.project.id
        this.timeTrackingUnit.ticket.project.fullName = this.existingTicket.project.fullName
        this.timeTrackingUnit.ticket.project.customer.fullName = this.existingTicket.project.customer.fullName
      }
    },
    setStartAndEndDatesForCurrentTTU() {
      // set startDate from start.date && start.time
      this.timeTrackingUnit.startDate = LocalDateTimeFormatter.toDateTime(
        this.timeTrackingUnit.start.date,
        this.timeTrackingUnit.start.time
      )
      // set endDate from end.date && end.time
      if (this.timeTrackingUnit.status === 'RUNNING') {
        this.timeTrackingUnit.endDate = null
        this.timeTrackingUnit.end.date = ''
        this.timeTrackingUnit.end.time = ''
      } else {
        this.timeTrackingUnit.endDate = LocalDateTimeFormatter.toDateTime(
          this.timeTrackingUnit.end.date,
          this.timeTrackingUnit.end.time
        )
      }
    },
    setStartTimeToCurrent() {
      this.timeTrackingUnit.start.time = this.getCurrentTime('start')
    },
    setEndTimeToCurrent() {
      this.timeTrackingUnit.end.time = this.getCurrentTime('end')
    },
    getCurrentTime(type) {
      const today = LocalDate.todayAsDayjs()
      let hours = today.hour()
      let minutes = today.minute()
      if (type === 'start') {
        minutes = Math.floor(minutes / 5) * 5
      }
      if (type === 'end') {
        minutes = Math.ceil(minutes / 5) * 5
      }
      if (minutes === 60) {
        hours = hours + 1
        minutes = 0
      }
      if (hours < 10) {
        hours = '0' + hours
      }
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      return hours + ':' + minutes
    },
    unselectIfQueryEmpty() {
      if (this.query === '' && this.query !== null) {
        this.dataTableControls.selected = ''
      }
    },
    reloadTable() {
      // TIM-468 don't reload if a reload is already in progress.
      // This is a guard for when both datepicker start and end dates are changed from a DatepickerQuickfilter component.
      if (this.dataTableControls.busy) {
        console.debug(
          'Table reload was triggered but another reload is already in progress. Aborting this reload request!'
        )
        return
      } else {
        console.debug('Table reload was triggered')
        this.dataTableControls.busy = true
      }

      return this.fetchTableData()
        .then(() => {
          this.$refs.timeTrackingUnitTable.refresh()
          this.dataTableControls.busy = false
          if (this.ttuStopwatch.status === 'CREATED') {
            this.ttuStopwatch.status = 'RUNNING'
          }
        })
        .catch(err => {
          this.dataTableControls.busy = false
          this.displayError('Error fetching TTUs: ' + err)
          this.ttuStopwatch.status = ''
        })
    },
    deleteModalSubmit() {
      switch (this.modalControl.delete.type) {
        case 'ttu':
          return this.deleteTimeTackingUnit()
        case 'ticket':
          return this.deleteTicket()
        default:
          console.debug('Deletion failed due to unknown modal type', this.modalControl.delete.type)
      }
    },
    resetCreationAndEditModal() {
      // reset objects and queries
      this.clearExistingTicket()
      this.clearTimetrackingUnit()
      this.ticketSuggestionQuery = ''
      this.parentTicketSuggestionQuery = ''
    },
    resetCreationModal() {
      // reset objects and queries
      this.resetCreationAndEditModal()

      // set startDate to current dateTime
      this.timeTrackingUnit.startDate = LocalDateTimeFormatter.toDateTime(
        LocalDate.today(),
        this.getCurrentTime('start')
      )

      // if table has items, get the latest, read the end date and set it as the new start date
      if (this.items && this.items.length > 0) {
        const latestItem = this.items[this.items.length - 1]
        const latestTtu = {
          endDate: latestItem.endDate,
          status: latestItem.status
        }
        latestTtu.end = {
          date: LocalDateTimeFormatter.toDate(latestTtu.endDate),
          time: LocalDateTimeFormatter.toTime(latestTtu.endDate)
        }

        let currentDate = LocalDate.today()
        if (latestTtu.end.date === currentDate && latestTtu.status === 'FINISHED') {
          this.timeTrackingUnit.startDate = latestTtu.endDate
        }
      }

      // set start.date and start.time strings from startDate property
      this.timeTrackingUnit.start = {
        date: LocalDateTimeFormatter.toDate(this.timeTrackingUnit.startDate),
        time: LocalDateTimeFormatter.toTime(this.timeTrackingUnit.startDate)
      }

      // set end.date property to the same day as the start day
      this.timeTrackingUnit.end.date = this.timeTrackingUnit.start.date

      // set status to RUNNING
      this.timeTrackingUnit.status = 'RUNNING'
    },
    clearTimetrackingUnit() {
      this.timeTrackingUnit = _cloneDeep(this.objectModels.timeTrackingUnit)
    },
    onEditModalClose() {
      this.clearTimetrackingUnit()
      this.parentTicketSuggestionQuery = ''
    },
    clearBreak() {
      this.break = {
        startTimeHours: null,
        startTimeMinutes: null,
        durationInMinutes: null
      }
    },
    clearTicketInCurrentTTU() {
      this.timeTrackingUnit.ticket = _cloneDeep(this.objectModels.ticket)
    },
    clearExistingTicket() {
      this.existingTicket = {
        id: '',
        name: '',
        ticketId: '',
        project: {
          id: '',
          fullName: '',
          customer: {
            fullName: ''
          }
        }
      }
    },
    getTicketUrl(item) {
      let id = item.ticket.ticketId
      if (id.startsWith('#')) {
        // that's probably a Gitlab ticket, we strip the #-prefix
        return item.ticket.project.jiraBaseUrl + id.substr(1)
      } else {
        return item.ticket.project.jiraBaseUrl + id
      }
    },
    durationInMinutesToTime(value) {
      return LocalDateTimeFormatter.durationInMinutesToTime(value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

#selectedTicketBreadcrumb {
  a {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
  }

  span {
    color: black;
  }
}

#runningTicketHolder {
  padding-right: 0;
}

.running-buttons {
  width: 100%;

  .b-icon {
    vertical-align: -0.2em;
  }
}

#editTicketBreadcrumb {
  a {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
  }

  span {
    color: black;
  }
}

.card-body {
  padding: 0.3rem;
}

#runningTimeTrackingUnitBreadcrumb {
  padding: 0.5rem;
  background-color: transparent;

  & > li::before {
    content: '';
  }

  & > li:not(:last-child)::after {
    padding-left: 0.5rem;
    color: #6c757d;
    content: '/';
  }

  a {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
  }

  span {
    color: black;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0;
  }
}

#editTimetrackingUnitBreadcrumb {
  a {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
  }

  span {
    color: black;
  }
}
</style>

<style lang="scss">
.text-black-30 {
  color: rgba(0, 0, 0, 0.3) !important;
}

#recentTicketsFilterUserId {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#autosuggest input {
  &,
  &:focus {
    padding: 6px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    width: 100%;
    text-indent: 7px;
    outline: none;
  }
}

#autosuggest .autosuggest__input-open {
  border: none;
}

.autosuggest__results-container {
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  background: white;
  padding: 0;
  max-height: 200px;
  overflow: auto;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  text-indent: 10px;

  &:active,
  &:hover,
  &:focus,
  &.autosuggest__results-item--highlighted {
    background-color: #ddd;
  }
}

#ttuCommentInAlert {
  background-color: transparent;
  border-color: transparent;

  &:hover,
  &:focus {
    &.warning {
      border-color: #856404;
    }

    &.info {
      border-color: #17a2b8;
    }
  }
}

.input-group > .input-group-prepend {
  & + #editTicketUidTypeahead input.form-control {
    border-radius: 0;
    margin-right: -1px;
  }

  & + * + #editTicketNameTypeahead input.form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

#cost-unit-link {
  color: #6c757d;
}

.generalOverview {
  max-width: 190px;
  background-color: #d1ecf1;
  color: white !important;
  border: 1px solid #bee5eb;
  border-radius: 4px;
}

.customerOverview {
  max-width: 190px;
  background-color: #dee2e6;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
</style>
